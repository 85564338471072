import { useParams } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useBlock } from "@/hooks/useBlock";
import { InfoCardCell } from "@/components/InfoCardCell";
import { PageContentHeader } from "@/components/PageContentHeader";
import { CopyableText } from "@/components/CopyableText";
import { formatDateToNow, satsToBTC } from "@/lib";
import { TransactionList } from "@/components/TransactionList";
import { NotFoundView } from "@/components/NotFoundView";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

function InfoCard({ title, data, className }: any) {
  return (
    <Card className={`flex flex-col overflow-hidden grow ${className}`}>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-2 grow">
        {data.map(({ title, value }: any) => (
          <InfoCardCell key={title} title={title} value={value} />
        ))}
      </CardContent>
    </Card>
  );
}

export function BlockPage() {
  const { id } = useParams();
  const { block, loading } = useBlock({
    heightOrHash: id ?? "",
  });

  if (loading) {
    return <div />;
  }

  if (!block) {
    return <NotFoundView title="This Block Does Not Exist Yet" />;
  }

  const summaryData = [
    {
      title: "Height",
      value: (
        <CopyableText
          text={block?.height}
          displayText={`#${Number(block?.height ?? 0).toLocaleString("en-US")}`}
        />
      ),
    },
    {
      title: "Hash",
      value: <CopyableText text={block?.hash} displayText={block?.hash} />,
    },
    {
      title: "Number of Transactions",
      value: (block?.txCount ?? 0)?.toLocaleString("en-US"),
    },
    {
      title: "Miner Reward Transaction",
      value: (
        <CopyableText
          text={block?.minerRewardTransactionId}
          displayText={block?.minerRewardTransactionId}
        />
      ),
    },
  ];

  const priorityFee = satsToBTC(BigInt(block?.priorityFee ?? 0));
  const powReward = satsToBTC(BigInt(block?.powReward ?? 0));
  const opnetFees = satsToBTC(BigInt(block?.burnedBitcoin ?? 0)) + powReward;

  const statsData = [
    {
      title: "Total Priority Fees",
      value: `${priorityFee?.toLocaleString("en-US", {
        maximumFractionDigits: 8,
      })} BTC`,
    },
    {
      title: "Total OP_NET Fees",
      value: `${opnetFees?.toLocaleString("en-US", {
        maximumFractionDigits: 8,
      })} BTC`,
    },
    {
      title: "Total Gas Used",
      value: `${satsToBTC(BigInt(block?.gasSats ?? 0))?.toLocaleString(
        "en-US",
        {
          maximumFractionDigits: 8,
        }
      )} BTC`,
    },
    {
      title: "Total POW Rewards",
      value: `${powReward?.toLocaleString("en-US", {
        maximumFractionDigits: 8,
      })} BTC`,
    },
    {
      title: "Total Contract Calls",
      value: `${block?.contractCallCount?.toLocaleString("en-US")}`,
    },
    {
      title: "Total Contract Deploys",
      value: `${block?.contractDeployCount?.toLocaleString("en-US")}`,
    },
  ];

  const stateData = [
    {
      title: "Confirmations",
      value: `${block?.confirmations?.toLocaleString("en-US")}`,
    },
    {
      title: "Validators",
      value: `${block?.validators?.total?.toLocaleString("en-US")}`,
    },
  ];

  const advancedInfoData = [
    {
      title: "Checksum Root",
      value: (
        <CopyableText
          text={block?.checksumRoot}
          displayText={block?.checksumRoot}
        />
      ),
    },
    {
      title: "Previous Block Checksum",
      value: (
        <CopyableText
          text={block?.previousBlockChecksum}
          displayText={block?.previousBlockChecksum}
        />
      ),
    },
    {
      title: "Previous Block Hash",
      value: (
        <CopyableText
          text={block?.previousBlockHash}
          displayText={block?.previousBlockHash}
        />
      ),
    },
    {
      title: "Merkle Root",
      value: (
        <CopyableText
          text={block?.merkleRoot}
          displayText={block?.merkleRoot}
        />
      ),
    },
    {
      title: "Storage Root",
      value: (
        <CopyableText
          text={block?.storageRoot}
          displayText={block?.storageRoot}
        />
      ),
    },
    {
      title: "Receipt Root",
      value: (
        <CopyableText
          text={block?.receiptRoot}
          displayText={block?.receiptRoot}
        />
      ),
    },
  ];

  return (
    <div className="flex container p-4 space-y-4 flex-col">
      <PageContentHeader
        title={`Block #${Number(block?.height ?? 0).toLocaleString("en-US")}`}
        accessory={block && formatDateToNow(block?.time)}
      />
      <div className="flex flex-col grow gap-4 justify-center">
        <div className="flex flex-col lg:flex-row gap-4">
          <InfoCard title="Summary" data={summaryData} className="lg:w-2/4" />
          <InfoCard title="Stats" data={statsData} className="lg:w-1/4" />
          <InfoCard title="State" data={stateData} className="lg:w-1/4" />
        </div>
        <Card className="flex flex-col overflow-hidden grow">
          <CardContent>
            <Accordion type="single" collapsible>
              <AccordionItem value="advanced">
                <AccordionTrigger>Advanced Info</AccordionTrigger>
                <AccordionContent className="flex flex-col gap-2 grow">
                  {advancedInfoData.map(({ title, value }) => (
                    <InfoCardCell key={title} title={title} value={value} />
                  ))}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </CardContent>
        </Card>
        <Card className="flex flex-col overflow-hidden grow">
          <CardContent className="p-0">
            <TransactionList
              compact={false}
              paginate={true}
              blockHash={block?.height}
            />
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
