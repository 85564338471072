import { ReactNode } from "react";

import { Separator } from "@/components/ui/separator";

import { cn } from "@/lib/utils";

export type InfoCardCellProps = {
  title: string;
  value: ReactNode;
  titleClassName?: string;
  valueClassName?: string;
  separatorClassName?: string;
};

export function InfoCardCell({
  title,
  value,
  titleClassName,
  valueClassName,
  separatorClassName,
}: InfoCardCellProps) {
  return (
    <>
      <Separator className={cn("first:hidden", separatorClassName)} />
      <div className="flex flex-col grow justify-center py-2 lg:py-0">
        <div
          className={cn(
            "text-sm font-medium text-muted-foreground text-ellipsis",
            titleClassName
          )}
        >
          {title}
        </div>
        {value && (
          <div
            className={cn("text-sm font-medium text-ellipsis", valueClassName)}
          >
            {value}
          </div>
        )}
      </div>
    </>
  );
}
