import { useRecoilValue } from "recoil";
import { Link, LinkProps } from "react-router-dom";
import { NetworkState } from "../state";
import { useMemo } from "react";

export function NetworkLink(args: LinkProps) {
  const network = useRecoilValue(NetworkState);
  const to: any = useMemo(() => {
    const appendNetworkParam = (href: string) => {
      const base = window.location.origin;
      const url = new URL(href, base);
      url.searchParams.set("network", network);
      return url.pathname + url.search + url.hash;
    };

    let newTo: LinkProps["to"];

    if (typeof args.to === "string") {
      newTo = appendNetworkParam(args.to);
    } else if (typeof args.to === "object" && args.to !== null) {
      const { pathname = "", search = "", hash = "" } = args.to;
      const combinedURL = `${pathname}${search}${hash}`;
      newTo = appendNetworkParam(combinedURL);
    } else {
      newTo = args.to;
    }
    return newTo;
  }, [args.to]);
  return <Link {...args} to={to} />;
}
