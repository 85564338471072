import { useMemo, useState } from "react";
import {
  AlertTriangle,
  ArrowRight,
  ArrowBigRight,
  Coins,
  Flame,
  FunctionSquare,
} from "lucide-react";
import { Link, useParams } from "react-router-dom";
import { Decimal } from "decimal.js";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { NetworkLink } from "@/components/NetworkLink";
import { PageContentHeader } from "@/components/PageContentHeader";
import { BTCPriceConversion } from "@/components/BTCPriceConversion";
import { Separator } from "@/components/ui/separator";
import { CopyableText } from "@/components/CopyableText";
import { AddressMapView } from "@/components/AddressMapView";
import { ArrayView } from "@/components/ArrayView";
import { Address } from "@/components/Address";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Collapsible,
  CollapsibleTrigger,
  CollapsibleContent,
} from "@/components/ui/collapsible";
import { TransactionEventsCard } from "@/components/TransactionEventsCard";
import { NotFoundView } from "@/components/NotFoundView";

import {
  formatDateToNow,
  truncateInMiddle,
  createTitleForTransaction,
  formatNumber,
  satsToBTC,
} from "@/lib";
import {
  useBlock,
  useSmartContract,
  useTransaction,
  useTransactionEvents,
} from "@/hooks";

function formatTokenAmount(amount: bigint, decimals: number = 8) {
  return new Decimal(`${amount}`)
    .dividedBy((10n ** BigInt(decimals)).toString())
    .toNumber();
}

function CardTableRow({ label, children }: any) {
  return (
    <div className="flex flex-col md:flex-row">
      <div className="py-1 font-medium w-44 text-muted-foreground">{label}</div>
      <div className="py-1 break-all font-medium">{children}</div>
    </div>
  );
}

function BlockLink({ height }: { height: string }) {
  const { block } = useBlock({ heightOrHash: `${height}` });
  const blockNumber = `#${Number(height).toLocaleString("en-US")}`;

  return block ? (
    <NetworkLink to={`/blocks/${height}`} className="underline">
      {blockNumber}
    </NetworkLink>
  ) : (
    <span>{blockNumber}</span>
  );
}

function TransactionActionLine({
  events,
  transaction,
}: {
  events: any[];
  transaction: any;
}) {
  const method = transaction?.decodedMethodArgs ?? {};

  if (!["transfer", "mint", "burn", "swap"].includes(method.name)) return null;

  function resolveTokenTitle(amount: string, op20Metadata: any) {
    const decimals = op20Metadata?.decimals ?? 8;
    const formattedAmount = formatTokenAmount(
      BigInt(amount),
      op20Metadata?.decimals ?? 8
    )?.toLocaleString("en-US", { maximumFractionDigits: decimals });
    const tokenName = op20Metadata?.symbol ?? op20Metadata?.name ?? "Token";
    return `${formattedAmount} ${tokenName}`;
  }

  const formattedAmount = 0;
  const tokenName = "Token";

  let actionText;
  let icon;

  switch (method.name) {
    case "mint":
      icon = <Coins className="h-4 w-4 mr-1 inline" />;
      actionText = (
        <span>
          Minted{" "}
          <Button className="p-0 h-auto" variant="link" key="title" asChild>
            <NetworkLink to={"a"}>
              {formattedAmount} {tokenName}
            </NetworkLink>
          </Button>{" "}
          to <Address address={method.props?.to?.value} copyable />
        </span>
      );
      break;
    case "burn":
      icon = <Flame className="h-4 w-4 mr-1 inline" />;
      actionText = `Burned ${formattedAmount} ${tokenName}`;
      break;
    case "transfer":
      icon = <ArrowBigRight className="h-4 w-4 mr-1 inline" />;
      actionText = `Transferred ${formattedAmount} ${tokenName}`;
      break;
    case "swap":
      const tokenInTitle = resolveTokenTitle(
        method?.action?.tokenIn?.amount ?? 0,
        method?.action?.tokenIn?.isNativeCurrency
          ? {
              name: "BTC",
              decimals: 8,
            }
          : method?.action?.tokenIn?.op20Metadata
      );
      const tokenOutTitle = resolveTokenTitle(
        method?.action?.tokenOut?.amount ?? 0,
        method?.action?.tokenOut?.isNativeCurrency
          ? {
              name: "BTC",
              decimals: 8,
            }
          : method?.action?.tokenOut?.op20Metadata
      );
      icon = <ArrowBigRight className="h-4 w-4 mr-1 inline" />;
      actionText = `Swapped ${tokenInTitle} for ${tokenOutTitle}`;
      <span>
        Swapped{" "}
        {method?.action?.tokenIn?.isNativeCurrency ? (
          tokenInTitle
        ) : (
          <Button className="p-0 h-auto" variant="link" key="title" asChild>
            <NetworkLink to={"a"}>{tokenInTitle}</NetworkLink>
          </Button>
        )}{" "}
        for{" "}
        <Button className="p-0 h-auto" variant="link" key="title" asChild>
          <NetworkLink to={"a"}>{tokenOutTitle}</NetworkLink>
        </Button>
      </span>;
      break;
    default:
      return null;
  }

  return (
    <CardTableRow label="Transaction Action">
      <div className="text-md font-medium flex items-center">
        {icon} {actionText}
      </div>
    </CardTableRow>
  );
}

function InteractedWithSection({
  transaction,
  events,
}: {
  transaction: any;
  events: any[];
}) {
  let interactedAddress = null;

  if (
    transaction?.type === "interaction" ||
    transaction?.type === "deployment"
  ) {
    interactedAddress = transaction?.contractAddress;
  } else if (
    transaction?.type === "generic" &&
    transaction?.outputs?.length > 0
  ) {
    const firstOutput = transaction?.outputs[0];
    interactedAddress = firstOutput?.scriptPubKey?.address ?? null;
  }

  if (!interactedAddress) return null;

  const transferEvents = events.filter((e) => e.type === "Transfer");

  return (
    <div className="mt-1">
      <div className="flex items-center space-x-2">
        <Address address={interactedAddress} copyable />
      </div>
      {transferEvents.length > 0 && (
        <div className="ml-4 mt-1 space-y-1">
          {transferEvents.map((ev: any, i: number) => {
            const meta = ev.contract?.op20Metadata;
            const amt = formatTokenAmount(
              BigInt(ev.properties?.amount?.value ?? 0),
              meta?.decimals ?? 8
            );
            const tName = meta?.symbol ?? meta?.name ?? "Token";
            return (
              <div key={i} className="text-xs">
                Transfer {amt} {tName} from{" "}
                <Address address={ev.properties?.from?.value} copyable /> to{" "}
                <Address address={ev.properties?.to?.value} copyable />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

function FunctionDecode({ transaction }: { transaction: any }) {
  const decodedMethodCall = useMemo(() => {
    const decodedMethod = transaction?.decodedMethodArgs ?? {};
    const args = Object.entries(decodedMethod?.props ?? {});
    return {
      name: decodedMethod?.name,
      args,
      argCount: args.length,
    };
  }, [transaction]);
  const [open, setOpen] = useState(false);
  const burnedAmount = satsToBTC(BigInt(transaction?.burnedBitcoin ?? 0));
  const gasAmount = satsToBTC(BigInt(transaction?.gasSats ?? 0));
  const minerFee = satsToBTC(
    BigInt(transaction.inputValue - transaction.outputValue)
  );
  const priorityFee = satsToBTC(BigInt(transaction?.priorityFee ?? 0));
  const powReward = satsToBTC(BigInt(transaction?.powReward ?? 0));
  const opnetFees = burnedAmount + powReward;
  const maxGas = burnedAmount + powReward - priorityFee;
  const gasUsage = gasAmount / maxGas;

  //if (!decodedMethodCall?.argCount) return null;

  return (
    <Card className="flex flex-col overflow-hidden grow">
      <CardContent className="flex flex-col grow px-4 py-2">
        <div className="text-sm">
          <div className="flex items-center">
            <Collapsible open={open} onOpenChange={setOpen}>
              <CollapsibleTrigger asChild>
                <Button variant="link" className="p-0 underline">
                  {open ? "Hide" : "Show"} More Details
                </Button>
              </CollapsibleTrigger>
              <CollapsibleContent className="flex flex-col gap-4">
                <div className="border p-4 rounded-xl bg-gray-50 mt-4">
                  <div className="flex items-center mb-2 font-semibold">
                    <FunctionSquare className="h-4 w-4 mr-1" />
                    Decoded Input
                  </div>
                  <table className="w-full text-sm">
                    <tbody>
                      <tr>
                        <td className="py-1 font-medium w-32">Function Name</td>
                        <td className="py-1 break-words">
                          {decodedMethodCall.name}
                        </td>
                      </tr>
                      {decodedMethodCall.args.map((arg: any, i: number) => (
                        <tr key={i}>
                          <td className="py-1 font-medium">{arg[0]}</td>
                          <td className="py-1 break-words">
                            {typeof arg[1]?.value === "object" ? (
                              Array.isArray(arg[1]?.value) ? (
                                <ArrayView array={arg[1]?.value} />
                              ) : (
                                <AddressMapView obj={arg[1]?.value} />
                              )
                            ) : (
                              arg[1]?.value
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Separator />
                <div className="text-sm">
                  <CardTableRow label="Priority Fee">
                    {`${formatNumber(priorityFee)} BTC `}
                    <BTCPriceConversion
                      amount={priorityFee}
                      className="text-xs text-gray-500 ml-1"
                    />
                  </CardTableRow>
                  <CardTableRow label="Gas Used">
                    {`${formatNumber(gasAmount)} BTC `}
                    <BTCPriceConversion
                      amount={gasAmount}
                      className="text-xs text-gray-500 ml-1"
                    />
                    {` - ${(gasUsage * 100).toFixed(0)}% Usage`}
                  </CardTableRow>
                  <CardTableRow label="Max Gas">
                    {`${formatNumber(maxGas)} BTC `}
                    <BTCPriceConversion
                      amount={maxGas}
                      className="text-xs text-gray-500 ml-1"
                    />
                  </CardTableRow>
                  <CardTableRow label="OP_NET Fees">
                    {`${formatNumber(opnetFees)} BTC `}
                    <BTCPriceConversion
                      amount={opnetFees}
                      className="text-xs text-gray-500 ml-1"
                    />
                  </CardTableRow>
                  <CardTableRow label="Miner Fee">
                    {`${formatNumber(minerFee)} BTC `}
                    <BTCPriceConversion
                      amount={minerFee}
                      className="text-xs text-gray-500 ml-1"
                    />
                  </CardTableRow>
                </div>
                <Separator />
                <div className="text-sm">
                  {typeof transaction.powDifficulty !== "undefined" && (
                    <CardTableRow label="POW Difficulty">
                      {transaction.powDifficulty}
                    </CardTableRow>
                  )}
                  {transaction.powReward && (
                    <CardTableRow label="POW Reward">
                      {formatNumber(powReward)} BTC
                    </CardTableRow>
                  )}
                  {typeof transaction.powVersion !== "undefined" && (
                    <CardTableRow label="POW Version">
                      {transaction.powVersion}
                    </CardTableRow>
                  )}
                </div>
              </CollapsibleContent>
            </Collapsible>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function InputsCard({ transaction }: { transaction: any }) {
  return (
    <Card className="overflow-hidden grow">
      <CardHeader>
        <CardTitle>
          <div className="flex items-center gap-2 justify-between">
            <div className="flex gap-2 items-center">Inputs</div>
          </div>
        </CardTitle>
      </CardHeader>
      {transaction?.inputs.length > 0 && (
        <CardContent className="pt-0 flex flex-col gap-2">
          {transaction.inputs.map((input: any, i: number) => (
            <div
              className="flex flex-col border-b py-2 last:border-b-0"
              key={i}
            >
              <div className="flex flex-row grow items-center justify-between gap-4">
                <div className="text-sm font-medium w-40 text-ellipsis">
                  <Address address={input.address} copyable />
                </div>
                <Badge
                  variant="outline"
                  className="font-medium gap-1 monospaced"
                >
                  <CopyableText
                    text={input.originalTransactionId}
                    displayText={
                      input.originalTransactionId &&
                      truncateInMiddle(input.originalTransactionId, 16)
                    }
                  />
                </Badge>
                <div className="text-sm font-medium text-ellipsis">
                  {satsToBTC(BigInt(input.value))} BTC
                </div>
              </div>
            </div>
          ))}
        </CardContent>
      )}
    </Card>
  );
}

function OutputsCard({ transaction }: { transaction: any }) {
  return (
    <Card className="overflow-hidden grow">
      <CardHeader>
        <CardTitle>
          <div className="flex items-center gap-2 justify-between">
            <div className="flex gap-2 items-center">Outputs</div>
          </div>
        </CardTitle>
      </CardHeader>
      {transaction?.outputs.length > 0 && (
        <CardContent className="p-5 pt-0 flex flex-col gap-2">
          {transaction.outputs.map((output: any, i: number) => (
            <div
              className="flex flex-col border-b py-2 last:border-b-0"
              key={i}
            >
              <div className="flex flex-row grow items-center justify-between gap-4">
                <div className="text-sm font-medium w-40 text-ellipsis">
                  {output.scriptPubKey?.address ? (
                    <Address address={output.scriptPubKey?.address} copyable />
                  ) : (
                    "No Address"
                  )}
                </div>
                <div className="text-sm font-medium text-ellipsis">
                  {satsToBTC(BigInt(output.value))} BTC
                </div>
              </div>
            </div>
          ))}
        </CardContent>
      )}
    </Card>
  );
}

function TransactionOverview({
  transaction,
  contract,
  events,
}: {
  transaction: any;
  contract: any;
  events: any[];
}) {
  const finalized = transaction?.block?.finalized ?? false;
  const inputValue = satsToBTC(BigInt(transaction?.inputValue ?? 0));
  const outputValue = satsToBTC(BigInt(transaction?.outputValue ?? 0));
  const type: string = useMemo(() => {
    switch (transaction?.type) {
      case "interaction":
        return "Contract Call";
      case "coinbase":
        return "Coinbase";
      case "deployment":
        return "Contract Deploy";
      case "generic":
        return "Transfer";
      default:
        return "Unknown";
    }
  }, [transaction]);

  return (
    <Card className="flex flex-col overflow-hidden grow">
      <CardHeader>
        <CardTitle className="flex justify-between items-center">
          <div>{transaction && createTitleForTransaction(transaction)}</div>
          <div>#{transaction?.index?.toLocaleString("en-US")}</div>
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-2 grow text-sm">
        <div>
          <CardTableRow label="Tx ID">
            <CopyableText
              text={transaction?.id}
              displayText={transaction?.id}
            />
          </CardTableRow>
          <CardTableRow label="Tx Hash">
            <CopyableText
              text={transaction?.hash}
              displayText={transaction?.hash}
            />
          </CardTableRow>
          <CardTableRow label="Type">
            <Badge>{type}</Badge>
          </CardTableRow>
          <CardTableRow label="Status">
            {transaction?.revert ? (
              <Badge className="bg-red-500 text-white">Reverted</Badge>
            ) : (
              <Badge className="bg-green-500 text-white">
                {finalized ? "Finalized" : "Not Finalized"}
              </Badge>
            )}
          </CardTableRow>
          <CardTableRow label="Block">
            {transaction?.blockHeight && (
              <BlockLink height={transaction?.blockHeight} />
            )}
          </CardTableRow>
          <CardTableRow label="Timestamp">
            {formatDateToNow(transaction?.blockTime)}
          </CardTableRow>
        </div>

        <Separator />

        <div>
          <TransactionActionLine events={events} transaction={transaction} />
          {transaction?.type === "interaction" && (
            <>
              <CardTableRow label="From">
                <Address address={transaction?.caller} copyable />
              </CardTableRow>
              <CardTableRow label="Interaction With (To)">
                <InteractedWithSection
                  transaction={transaction}
                  events={events}
                />
              </CardTableRow>
            </>
          )}
          {transaction?.type === "deployment" && (
            <CardTableRow label="Deployer">
              <Address address={transaction?.deployerAddress} copyable />
            </CardTableRow>
          )}
          {transaction?.type === "generic" &&
            transaction?.inputAddresses?.length === 1 && (
              <CardTableRow label="From">
                <Address address={transaction?.inputAddresses[0]} copyable />
              </CardTableRow>
            )}
          {transaction?.type === "generic" &&
            transaction?.outputAddresses?.length === 1 && (
              <CardTableRow label="To">
                <Address address={transaction?.outputAddresses[0]} copyable />
              </CardTableRow>
            )}
          {transaction?.type === "deployment" && (
            <CardTableRow label="Contract">
              <CopyableText
                text={transaction?.contractAddress}
                displayText={
                  transaction?.contractAddress +
                  (contract?.op20Metadata?.symbol
                    ? ` ($${contract?.op20Metadata?.symbol})`
                    : "")
                }
                href={`/accounts/${transaction?.contractAddress}`}
              />
            </CardTableRow>
          )}
          <CardTableRow label="Input Value">
            {`${formatNumber(inputValue)} BTC `}
            <BTCPriceConversion
              amount={inputValue}
              className="text-xs text-gray-500 ml-1"
            />
          </CardTableRow>
          <CardTableRow label="Output Value">
            {`${formatNumber(outputValue)} BTC `}
            <BTCPriceConversion
              amount={outputValue}
              className="text-xs text-gray-500 ml-1"
            />
          </CardTableRow>
        </div>
      </CardContent>
    </Card>
  );
}

export function TransactionPage() {
  const { id } = useParams();
  const { transaction, loading } = useTransaction({
    hash: id ?? "",
    enrichAction: true,
  });
  const { contract } = useSmartContract({
    id: transaction?.contractAddress,
  });
  const { events } = useTransactionEvents({
    transactionId: transaction?.id,
    includeContracts: true,
  });

  if (loading) return null;
  if (!transaction) {
    return <NotFoundView title="This Transaction Does Not Exist Yet" />;
  }

  return (
    <div className="container p-3 flex flex-col gap-3">
      {transaction?.revert && (
        <Card className="flex flex-col overflow-hidden grow bg-destructive">
          <CardContent className="flex flex-row items-center gap-2 text-white p-4">
            <AlertTriangle className="h-4 w-4" />
            {transaction.revert}
          </CardContent>
        </Card>
      )}

      <Tabs defaultValue="overview">
        <TabsList className="mb-2">
          <TabsTrigger value="overview">Overview</TabsTrigger>
          {transaction?.eventCount > 0 && (
            <TabsTrigger value="events">Events</TabsTrigger>
          )}
        </TabsList>

        <TabsContent value="overview" className="flex flex-col gap-4">
          <TransactionOverview
            transaction={transaction}
            contract={contract}
            events={events ?? []}
          />
          <FunctionDecode transaction={transaction} />
          <div className="flex flex-col lg:flex-row gap-4">
            {transaction?.inputs.length > 0 && (
              <InputsCard transaction={transaction} />
            )}
            {transaction?.outputs.length > 0 && (
              <OutputsCard transaction={transaction} />
            )}
          </div>
        </TabsContent>

        {transaction?.eventCount > 0 && (
          <TabsContent value="events">
            <TransactionEventsCard transaction={transaction} />
          </TabsContent>
        )}
      </Tabs>
    </div>
  );
}
