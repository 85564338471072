import { DollarSign } from "lucide-react";
import { Link } from "react-router-dom";

import { NetworkLink } from "@/components/NetworkLink";
import { CenteredRowCell } from "@/components/CenteredRowCell";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { truncateInMiddle, formatNumber, satsToBTC } from "@/lib";

import { CommonRow } from "@/components/CommonRow";
import { CopyableText } from "@/components/CopyableText";
import { BTCPriceConversion } from "@/components/BTCPriceConversion";
import { Address } from "@/components/Address";

export type TransferRowProps = {
  transfer: any;
  address?: string;
  compact?: boolean;
  className?: string;
};

export function TransferRow({
  transfer,
  compact = false,
  className,
}: TransferRowProps) {
  const amount = satsToBTC(transfer?.addressReceivedValue ?? 0);
  return (
    <CommonRow
      icon={DollarSign}
      title={
        <div className="flex flex-row items-center gap-1">
          {`Received ${formatNumber(amount)} BTC`}
          <div className="text-xs font-medium text-muted-foreground">
            <BTCPriceConversion amount={amount} />
          </div>
        </div>
      }
      subtitle={
        transfer.inputAddresses?.length > 0 ? (
          <Button variant="link" size="none">
            <Address label="From: " address={transfer?.inputAddresses[0]} />
            {transfer.inputAddresses?.length > 1 && (
              <> & {transfer.inputAddresses?.length - 1} others</>
            )}
          </Button>
        ) : (
          <>From: {transfer?.inputs?.length} Inputs</>
        )
      }
      className={className}
      href={`/transactions/${transfer?.id}`}
    >
      <CenteredRowCell>
        <Badge variant="outline" className="font-medium gap-1 monospaced">
          <CopyableText
            text={transfer?.id}
            displayText={transfer?.id && truncateInMiddle(transfer?.id, 10)}
          />
        </Badge>
      </CenteredRowCell>
    </CommonRow>
  );
}
